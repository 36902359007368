//接口请求域名
var API_URL = "api/";
if(process.env.NODE_ENV == 'development'){
	API_URL = "api/";
}else{
	API_URL = "api/";
}
var LOGIN_URL = "";
//接口地址
export default{
	// loginUrl: LOGIN_URL + 'login/uaa/oauth/token',
	loginUrl: API_URL + 'login/cust',
	loginOutUrl: API_URL + 'login/cust/loginOut',
	custRefreshToken: API_URL + 'login/custRefreshToken',
//   ownerData: LOGIN_URL + 'login/member/login/index',
	getmemberData: API_URL + 'pms/member/info',
//   safeCodeUrl: LOGIN_URL + 'login/mss/smsCode',
	safeCodeUrl: API_URL + 'login/cust/smsCode',
	getemailLoginCode: API_URL + 'login/cust/emailCode',
  // getemailLoginCode: LOGIN_URL + 'login/mss/emailCode',
	getemailCode: LOGIN_URL + 'login/member/user/emailCode',
	postHotellist: API_URL + "hotel/hotelSearch", //民宿宾馆列表
  	pmsPage: API_URL + "pms/page", // 页面配置信息
	getrefundStrategyText: API_URL + "pms/refundStrategyText", // 取消政策
	esLoadRoomFacility: API_URL + "pms/esLoadRoomFacility", // 筛选条件
	hotelinfobyidapp: API_URL + "pms/hotelinfobyidapp", //房源详情
	bookingorderSave: API_URL + "pms/bookingorder/save/v2", //下单
	bookingPmsSite: API_URL + "pms/site", //预订平台数据加载
	pay4order: API_URL + "pms/pay4order", //去支付
	hotelOrderList: API_URL + "pms/order/list", //旅馆订单列表
	hotelOrderDetail: API_URL + "pms/order/detail", //订单详情
	permitMemberPay: API_URL + "pms/book/permitMemberPay", //获取用户账户信息
	hotelRefundApply: API_URL + "pms/order/refund/apply", //申请退款
	hotelpriceByDate: API_URL + "pms/priceByDate", //房源详情日历价格
	postExtraPerson: API_URL + "pms/order/extraPerson", //下单多人数请求
	postRoomExtraPerson: API_URL + "pms/order/room/extraPerson", //下单多人数请求
	todayCheckInOrder: API_URL + "pms/order/todayCheckIn", //当天入住
	cancelOrder: API_URL + "pms/book/cancelOrder/v2", //取消订单
	getInvoice:API_URL + "pms/order/invoice",//获取收据
	setUserLang:API_URL + "pms/setUserLang",//设置用户语言
	orderRoomUnlock:API_URL + "pms/orderRoomUnlock",//入住
	custChecked:API_URL + "pms/book/cust/checked",//用户入住
	getcouponsList:API_URL + "pms/coupons/custListV2",//优惠券列表
	getabcustList:API_URL + "pms/coupons/order/custListV2",//可用优惠券
	getactivewindow:API_URL + "pms/homePage/window",//是否显示优惠券
	savecustSetInfo:API_URL + "pms/member/custSetInfo",//设置用户信息
	pmsmemberSendCode: API_URL + 'pms/member/sendCode', //发送验证码
	pmsmemberCheckCode: API_URL + 'pms/member/checkCode',//检测验证码
	getmemberContactsList: API_URL + 'pms/member/memberContactsList',//获取常用人列表
	memberContactsSaveOrUpdate: API_URL + 'pms/member/memberContactsSaveOrUpdate',//保存联系人
	memberContactsDelete: API_URL + 'pms/member/memberContactsDelete',//删除联系人
	orderapproval:API_URL + "pms/email/cancel/order/approval",//取消
	getcancelOrderRule:API_URL + "pms/book/cancelOrderRule",//退款政策
	getactivations:API_URL + "pms/homePage/activations",//调取活动
	gethomeRecommend:API_URL + "hotel/homeRecommend",//调取首页推荐
	payCreditCard:API_URL + "creditCard/payAndAuth",//支付
	getbuildingCode:API_URL + "hotel/buildingCode",//获取酒店类型
	updatebookingorder:API_URL + "pms/bookingorder/update",//更新个人信息
	h5alipay:API_URL + "alipay/h5/pay",//支付宝支付
}
